<template>
  <ad-accounts-table :headers="headers" :ad_accounts="ad_accounts" :loading="loading" class="mt-7" />
</template>

<script>
import AdAccountsTable from "../../../components/Tables/AdAccountsTable.vue";
import { facebookMixin } from "../../../mixins/facebookMixin";

export default {
  components: { AdAccountsTable },
  mixins: [facebookMixin],
  data: () => ({
    error: null,
    loading: false,
    headers: [
      {
        text: "Ad Account",

        sortable: false,
        value: "name",
        // width: "400px",
      },
      {
        text: "Currency",
        sortable: false,
        value: "currency",
        // width: "400px"
      },
      {
        text: "Amount Spent",
        sortable: false,
        value: "amount_spent",
        // width: "400px"
      },
      {
        text: "Balance",

        sortable: false,
        value: "balance",
        // width: "400px"
      },
    ],
  }),

  component: {
    AdAccountsTable,
  },
};
</script>
