<template>
  <v-card id="adaccounts" outlined>
    <v-card-title>
      <h5>Facebook Ad Accounts</h5>
    </v-card-title>
    <v-divider></v-divider>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="ad_accounts"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
      hide-default-footer
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:item.name="{ item }">
        <router-link
          :to="{
            name: 'get-ad-account',
            params: {
              id: item.id,
            },
          }"
        >
          <span class="font-weight-bold"> {{ item.name }}</span>
        </router-link>
      </template>
      <!-- eslint-disable-next-line-->
      <template v-slot:item.currency="{ item }">
        {{ item.currency }}
      </template>
      <!-- eslint-disable-next-line-->
      <template v-slot:item.amount_spent="{ item }">
        {{
          (item.amount_spent / 100).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
        }}
      </template>
      <!-- eslint-disable-next-line-->
      <template v-slot:item.balance="{ item }">
        {{
          (item.balance / 100).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
        }}
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-card-actions class="d-flex align-center pa-4">
      <div class="caption mr-3">
        {{ `Page: ` + page + ` of ` + pageCount }}
      </div>
      <v-select
        v-model="itemsPerPage"
        :items="[10, 25, 50, 100, 250]"
        label="Items per page"
        outlined
        dense
        hide-details
        @input="itemsPerPage = parseInt($event, 10)"
        class="shrink"
        :menu-props="{ top: true, offsetY: true }"
      ></v-select>

      <v-spacer></v-spacer>

      <v-pagination v-model="page" :length="pageCount" class="custom"></v-pagination>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
    };
  },
  props: {
    ad_accounts: {
      type: Array,
    },
    headers: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
  },
  methods: {
    navigateToSingleAdaccount(accountId) {
      this.$router.push({
        name: "get-ad-account",
        params: {
          id: accountId.substr(4, accountId.length),
        },
      });
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
